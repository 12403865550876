<template>
  <v-sheet
    :min-height="$vuetify.breakpoint.smAndDown ? '45vh' : '300px'"
    color="grey lighten-3"
    height="20vh"
  >
    <v-container fill-height>
      <v-row
        align-content="center"
        justify="center"
        class="fill-height"
      >
        <v-col cols="12">
          <h1
            class="display-2 text-center"
          >
            404
          </h1>
        </v-col>
        <v-col cols="12">
          <p
            class="text-center"
          >
            Something went wrong. Please contact support at info@adent.dk
          </p>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
  export default {
    data () {
      return {
      }
    },
  }
</script>
